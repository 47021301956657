import React, { useEffect, useRef, useState } from 'react';
import logo from './logo.svg';
import {  BrowserRouter as Router, Route, useParams} from 'react-router-dom';
import { FreeCamera, Vector3, HemisphericLight, MeshBuilder, Scene, SceneLoader, Color4, UniversalCamera, ArcRotateCamera, TransformNode, GlowLayer, Tools, Camera } from "@babylonjs/core";
import SceneComponent from "babylonjs-hook";
import "@babylonjs/loaders/glTF";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { getBgColorById } from '../helpers/helpers';


function MonksViewer() {
  let { id } = useParams();
  const [anims, setAnims] = useState<any>([]);
  const [ag, setAg] = useState("Idle 01");
  const [_scene, setScene] = useState<any>(null);
  const [check, setCheck] = useState(false);

  const onSceneReady = (scene: Scene) => {
    // This creates and positions a free camera (non-mesh)
    var camera = new ArcRotateCamera("camera1", 0, 0, 10, new Vector3(20, 60, -150), scene);
    // This targets the camera to scene origin
    camera.setTarget(new Vector3(0, 40, 0));
    camera.inputs.addMouseWheel();
    camera.id = "main";
    
    const canvas = scene.getEngine().getRenderingCanvas();
  
    // This attaches the camera to the canvas
    camera.attachControl(canvas, true);

    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    var light = new HemisphericLight("light", new Vector3(0, 2, 0), scene);
  
    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = .65;
  
  
    SceneLoader.Append("https://gateway.pinata.cloud/ipfs/QmWnhSHawFa4GVs2ECPNdSsPwCEGHLNMjn6G3YJAc8g9wi/", `${id}.glb`, scene, (scene: Scene) => {
    // SceneLoader.Append("http://localhost:3000/models/", `${id}.gltf`, scene, (scene: Scene) => {
      scene.clearColor = new Color4(0, 0, 0, 0);
      let customMesh = scene.meshes[0]
      var pivot = new TransformNode("root");
      customMesh.parent = pivot;
      pivot.position = customMesh.getBoundingInfo().boundingBox.centerWorld;
      pivot.rotation.z -= Math.PI
      pivot.rotation.y -= Math.PI
      let ags: string[] = [];
      scene.animationGroups.forEach(a => {
        ags.push(a.name);
      })
      setAnims(ags);
      scene.getAnimationGroupByName("Idle 01")?.start(true);
      setScene(scene);
      var gl = new GlowLayer("glow", scene);
      gl.intensity = 0.6;

    });
    

  };
  
  const onRender = (scene: Scene) => {
    if (check === false) {
      let cam: any =  scene.getCameraByID("main");
    } else {
    }
      // var deltaTimeInMillis = scene.getEngine().getDeltaTime();
  
      // const rpm = 10;
      // box.rotation.y += (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000);
  };



  const onSelect = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    setAg(event.target.value)
  }

  useEffect(() => {
    if (_scene !== null) {
      _scene.stopAnimation();
      _scene.getAnimationGroupByName(ag)?.start(true);
    }
  }, [ag])
  
  // #f2f261
  return (<SceneComponent engineOptions={{preserveDrawingBuffer: true, stencil: true}}  style={{height: "100%", width: "100%", backgroundImage: `linear-gradient(to top, #5762ff, #303cdb)`}} antialias onSceneReady={onSceneReady} onRender={onRender} id="my-canvas">

  {/* return (<SceneComponent style={{height: "100%", width: "100%", backgroundImage: `linear-gradient(to top, #69ffb7, #b2f7d6)`}} antialias onSceneReady={onSceneReady} onRender={onRender} id="my-canvas"> */}
          {
            anims.length > 0 &&
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ag}
              onChange={onSelect}
              style={{position: "fixed", left: 30, top: 20}}
            >
              {
                anims.map((a: string, i: number) => {
                  return <MenuItem key={i} value={a}>{a.split("0")[0]}</MenuItem>
                })
              }
            </Select>
          }
  </SceneComponent>);
}




export default MonksViewer;
