import types from '../data/species_types'

const mappings = [
    {},
    {
        "color1": "#bfffff",
        "color2": "#59ffff"
    },
    {
        "color1": "#fff2b0",
        "color2": "#f7e37c"
    },
    {
        "color1": "#fca7fa",
        "color2": "#fc72f8"
    },
    {
        "color1": "#a1ffe6",
        "color2": "#4affcf"
    },
    {
        "color1": "#dc95fc",
        "color2": "#ca5ffa"
    },
    {
        "color1": "#3b353a",
        "color2": "#70256e"
    },
    {
        "color1": "#60e5fc",
        "color2": "#4affbd"
    },
    {
        "color1": "#be79f2",
        "color2": "#8624d1"
    },
    {
        "color1": "#f06e60",
        "color2": "#d13030"
    },
    {
        "color1": "#8ca9d1",
        "color2": "#4c87d9"
    },
    {
        "color1": "#1c0207",
        "color2": "#f72351"
    },
    {
        "color1": "#1c0207",
        "color2": "#dc8aff"
    },
    {
        "color1": "#81eafc",
        "color2": "#fcc25d"
    },
    {
        "color1": "#81eafc",
        "color2": "#0580b5"
    },
    {
        "color1": "#57f279",
        "color2": "#0580b5"
    },
    {
        "color1": "#57f279",
        "color2": "#1eb03e"
    },
    {
        "color1": "#d66c24",
        "color2": "#f29877"
    },
    {
        "color1": "#fcfc62",
        "color2": "#9cffe1"
    },
    {
        "color1": "#363604",
        "color2": "#fafa75"
    },
    {
        "color1": "#5e319e",
        "color2": "#b468f7"
    },
    {
        "color1": "#45060b",
        "color2": "#ff4757"
    },
    {
        "color1": "#f032e6",
        "color2": "#87d5fa"
    },
    {
        "color1": "#24c956",
        "color2": "#f5a351"
    },
    {
        "color1": "#fa2323",
        "color2": "#43fae8"
    }
]

export const getBgColorById = (id: any) => {
    
    let speciesId = 0;
    if (Number(id) === 6969) {
        speciesId = 11
    } else if (Number(id) === 7305) {
        speciesId = 12
    } else if (Number(id) === 8024) {
        speciesId = 13
    } else if (Number(id) === 7823) {
        speciesId = 14
    } else if (Number(id) === 8834) {
        speciesId = 15
    } else if (Number(id) === 7020) {
        speciesId = 16
    } else if (Number(id) === 9047) {
        speciesId = 17
    } else if (Number(id) === 8405) {
        speciesId = 18
    } else if (Number(id) === 7942) {
        speciesId = 19
    } else if (Number(id) === 7584) {
        speciesId = 20
    } else if (Number(id) === 8746) {
        speciesId = 21
    } else if (Number(id) === 8149) {
        speciesId = 22
    } else if (Number(id) === 9950) {
        speciesId = 23
    } else if (Number(id) === 7610) {
        speciesId = 24
    }     else {
        types.forEach((t) => {
            if (t.id == id) {
                speciesId = t.species;
            }
        });
    }

    return mappings[speciesId];

}