import React, { useEffect, useRef, useState } from 'react';
import {  BrowserRouter as Router, Route, useParams} from 'react-router-dom';
import SceneComponent from "babylonjs-hook";
import "@babylonjs/loaders/glTF";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { getBgColorById } from '../helpers/helpers';
import Spritesheet from './Spritesheet';


function SpritesheetViewer() {

    const { id } = useParams();
    const [url, setUrl] = useState("");
    const [startFrame, setStartFrame] = useState(1);
    const [endFrame, setEndFrame] = useState(4);
    const [frameCount, setFrameCount] = useState(112);
    const [fps, setFps] = useState(8);
    const [bgColor, setBgColor] = useState<any>("");
    const [sheet, setSheet] = useState<any>();

    const [selectedAnim, setSelectedAnim] = useState("Walk Down");

    const anims = [
        ["Walk Down", [1,4,6]],
        ["Walk Right", [9,12,6]],
        ["Walk Up", [17,20,6]],
        ["Walk Left",[25,28,6]],
        ["Run Down", [65, 72, 18]],
        ["Run Right", [73, 80, 18]],
        ["Run Up", [81, 88, 18]],
        ["Run Left", [89, 96, 18]],
        ["Bonk Left", [97, 98, 7]],
        ["Bonk Right", [99,100,7]],
        ["Bonk Down", [101,102,7]],
        ["Bonk Up", [103,104,7]],
        ["Sleep Left", [105,106,1]],
        ["Sleep Right", [107,108,1]],
        // ["Sit Right", [109,109,1]],
        // ["Sit Down", [110,110,1]],
        // ["Sit Up", [111,111,1]],
        // ["Sit Left", [112,112,1]],
    ]

    
    useEffect(() => {
        updateUrl();
        setBgColor(getBgColorById(id)["color1"]);
    }, [id])
    
    const updateUrl = () => {
        setUrl("https://vx-stills.s3.us-east-2.amazonaws.com/sheets/" + String(id) + ".png")
    }

    const onSelect = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        anims.forEach((anim: any[]) => {
            if (anim[0] == event.target.value) {
                console.log(anim)
                setSelectedAnim(anim[0]);
             
                sheet?.setEndAt(anim[1][1]);
                sheet?.setStartAt(anim[1][0]);
                sheet?.goToAndPlay(anim[1][0]);
                sheet?.setFps(anim[1][2]);
                // sheet?.play();
            }
        })
    }

    return(
        <div 
            style={{
                backgroundColor: bgColor,
                height: "100vh"
            }}
        >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedAnim}
              onChange={onSelect}
              style={{position: "fixed", left: 20, top: 10}}
            >
              {
                anims.map((a: any[], i: number) => {
                  return <MenuItem key={i} value={a[0]}>{a[0]}</MenuItem>
                })
              }
            </Select>
            {
                url !== "" &&
                <Spritesheet
                    className="Sprite"
                    style={{backgroundColor: bgColor}}
                    image={url}
                    widthFrame={32}
                    heightFrame={32}
                    steps={frameCount}
                    fps={8}
                    startAt={startFrame}
                    endAt={endFrame}
                    loop={true}
                    autoplay={true}
                    direction='forward'
                    onInit={(spritesheet: any) => {setSheet(spritesheet)}}
                />
                // <SpriteAnimator
                //     className="Sprite" 
                //     sprite={url}
                //     width={32}
                //     height={32}
                //     scale={.07}
                //     shouldAnimate={true}
                //     startFrame={startFrame}
                //     frameCount={frameCount}
                //     fps={fps}
                // />
            }
        </div>
    )

}

export default SpritesheetViewer;