import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Routes, BrowserRouter as Router, Route} from 'react-router-dom';
import VXViewer from './components/VXViewer';
import MonksViewer from './components/MonksViewer';
import SpritesheetViewer from './components/Spritesheetviewer';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="vx/:id" element={<VXViewer/>}/>
        <Route path="monks/:id" element={<MonksViewer/>}/>
        <Route path="sprite/:id" element={<SpritesheetViewer/>}/>
      </Routes>
    </Router>
  );
}

export default App;
